import { Loader } from '@120wateraudit/envirio-components'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import {
  CommunicationType,
  TriggerType,
  CommunicationStatus,
  AccountType
} from '@120wateraudit/communications-types'
import { useQuery } from '@apollo/client'

import { pwsClient } from 'src/apollo-clients'
import { ReactComponent as CheckIcon } from 'src/assets/images/Check.svg'
import { ReactComponent as LetterIcon } from 'src/assets/images/Letter.svg'
import { ReactComponent as HazardIcon } from 'src/assets/images/Hazard.svg'
import { useFeatures } from 'src/hooks'
import { useToggle } from 'src/hooks/useToggle'
import {
  ButtonsWrapper,
  ErrorMessage,
  ModalContent,
  ModalHeading,
  Wrapper
} from 'src/modules/Modal/shared'
import { ModalWrapper } from 'src/modules/Samples/modals/CompleteSamplesModal/shared'
import SendModalContent from './SendModalContent'
import SendModalFooter from './SendModalFooter'
import { SupportLink } from 'src/components/SupportLink'
import { NoTemplatesWarning } from 'src/components/Communications/NoTemplatesWarning'
import { Link } from 'react-router-dom'
import {
  AccountSoftwarePackage,
  GET_PACKAGE_NAME_FOR_ACCOUNT_QUERY
} from 'src/containers/Communications/Dashboard/dataAccess'
import { SoftwarePackageName } from 'src/types/enums/SoftwarePackageName'
import {
  useGetAllCommunicationsQuery,
  useGetCommunicationQuery
} from 'src/services/communications-two-api'
import { Location } from 'src/types/Location'
import { CommunicationV2 } from 'src/types/CommunicationsV2'
import PDFModalContainerV2 from 'src/containers/Locations/SendCommunicationButton/PDFModalContainerV2'
import { skipToken } from '@reduxjs/toolkit/query'
import { usePerformBulkActionByTypeMutation } from 'src/modules/ServiceLines/dataAccess'
import { BulkActionType } from 'src/types/enums/BulkActionType'
export type CommunicationOption = Pick<
  CommunicationV2,
  'id' | 'name' | 'templateId'
>

const SendCommunicationModalTwo: React.FC<Props> = ({
  accountId,
  hideInventoryChoices,
  locationIds: baseLocationIds,
  locations,
  onClose,
  onCompletion,
  programId,
  eventId
}) => {
  const { data: commsData, isLoading: isLoadingComms } =
    useGetAllCommunicationsQuery({
      hideInventoryChoices,
      searchParams: {
        activeFilters: {
          'communications.communicationType': 'Letter',
          'communications.status': CommunicationStatus.Active,
          'communications.triggerType': TriggerType.AdHocLocation
        },
        page: 1,
        pageSize: 10000
      }
    })

  const communications = commsData?.data ?? []

  const [
    sendBulkAssetCommunications,
    { isLoading: isCommunicationPostLoading }
  ] = usePerformBulkActionByTypeMutation()

  const [selectedCommunication, setSelectedCommunication] = useState<
    undefined | CommunicationV2
  >(undefined)

  const { data: communicationData, isLoading: isLoadingCommunication } =
    useGetCommunicationQuery(
      selectedCommunication && selectedCommunication.id
        ? { communicationId: selectedCommunication.id }
        : skipToken
    )

  const { accountHasCommsAutomatedMailingFeature } = useFeatures()
  const [openPreviewContainer, togglePreviewContainer] = useToggle(false)
  const [isCertifiedMail, toggleIsCertifiedMail] = useToggle(false)
  const [selectedCommunicationType, setSelectedCommunicationType] = useState<
    undefined | CommunicationTypes
  >(undefined)
  const [stepNumber, setStepNumber] = useState<number>(0)
  const [zIndex, setZIndex] = useState<number>(1000)
  const [errorMessage, setErrorMessage] = useState<undefined | string>()

  const condensedLocations: number[] = useMemo(
    () => locations.map(({ id }) => id),
    [locations]
  )

  const { data: accountSoftwarePackage } = useQuery<AccountSoftwarePackage>(
    GET_PACKAGE_NAME_FOR_ACCOUNT_QUERY,
    {
      client: pwsClient,
      variables: { accountId: accountId },
      skip: !accountId
    }
  )

  const clearState = useCallback(() => {
    setSelectedCommunication(undefined)
    setSelectedCommunicationType(undefined)
    setStepNumber(0)
  }, [])

  const onNextClicked = useCallback(() => {
    if (stepNumber !== 2 && selectedCommunication) {
      setStepNumber(stepNumber + 1)
    }
  }, [stepNumber, selectedCommunication, selectedCommunicationType])

  const onBackClicked = useCallback(() => {
    if (stepNumber === 1) {
      setStepNumber(0)
    }
  }, [stepNumber])

  const onSend = useCallback(async () => {
    if (selectedCommunication !== undefined) {
      try {
        sendBulkAssetCommunications &&
          (await sendBulkAssetCommunications({
            bulkActionType: BulkActionType.SendCommunications,
            accountType: AccountType.PWS,
            communicationId: selectedCommunication.id,
            isCertifiedMail,
            programId,
            eventId,
            locationIds: condensedLocations
          }).unwrap())

        if (onCompletion) onCompletion()
        onNextClicked()
      } catch (error) {
        const errorMessage =
          error.data?.errors?.length > 0
            ? error.data?.errors[0]?.detail
            : 'An error occurred. Close and try again.'

        setErrorMessage(errorMessage)
      }
    }
  }, [
    accountId,
    isCertifiedMail,
    onCompletion,
    onNextClicked,
    selectedCommunication,
    setErrorMessage
  ])

  const onCloseWindow = useCallback(() => {
    clearState()
    onClose()
  }, [clearState, onClose])

  const closePreview = useCallback(() => {
    setZIndex(1000)
    togglePreviewContainer()
  }, [togglePreviewContainer])

  const fetchCommunicationData = useCallback(async () => {
    if (selectedCommunication?.id) {
      togglePreviewContainer()
    }
  }, [accountId, selectedCommunication, togglePreviewContainer])

  const onDownload = useCallback(async () => {
    if (selectedCommunication !== undefined) {
      try {
        sendBulkAssetCommunications &&
          (await sendBulkAssetCommunications({
            bulkActionType: BulkActionType.SendCommunications,
            accountType: AccountType.PWS,
            communicationId: selectedCommunication.id,
            download: true,
            programId,
            eventId,
            locationIds: condensedLocations
          }).unwrap())

        onNextClicked()
      } catch (error) {
        const errorMessage =
          error.data?.errors?.length > 0
            ? error.data?.errors[0]?.detail
            : 'An error occurred. Close and try again.'

        setErrorMessage(errorMessage)
      }
    }
  }, [
    accountId,
    onNextClicked,
    selectedCommunication,
    baseLocationIds,
    setErrorMessage
  ])

  const onCommunicationSelected = useCallback(
    ({ value }: { value: string }) => {
      const selected = communications.find(c => c.id === value)
      setSelectedCommunication(selected)
    },
    [communications]
  )

  const onCommunicationTypeSelected = useCallback(
    ({ value }: { value: CommunicationTypes }) => {
      setSelectedCommunicationType(CommunicationTypes[value])
    },
    []
  )

  const isPlural = baseLocationIds.length > 1
  const renderHeading = useCallback(() => {
    if (errorMessage) {
      return 'Error Occurred.'
    }

    if (selectedCommunicationType === CommunicationTypes['Letter as PDF']) {
      if (stepNumber === 1) {
        return `Print Communication${isPlural ? 's' : ''}`
      }

      if (stepNumber === 2) {
        return 'Your downloads have begun processing!'
      }
    }

    if (selectedCommunicationType === 'Letter') {
      if (stepNumber === 1) {
        return `Send Letters`
      }

      if (stepNumber === 2) {
        return 'Completed!'
      }
    }

    return `Send Communication${isPlural ? 's' : ''}`
  }, [errorMessage, isPlural, stepNumber, selectedCommunicationType])

  const renderIcon = useCallback(() => {
    if (errorMessage) {
      return HazardIcon
    }

    if (stepNumber === 2) {
      return CheckIcon
    }

    return LetterIcon
  }, [errorMessage, stepNumber, selectedCommunicationType])

  if (
    !accountHasCommsAutomatedMailingFeature &&
    accountSoftwarePackage?.accountSoftwarePackageName ===
      SoftwarePackageName.PwsPortal
  ) {
    return (
      <ModalWrapper style={{ gap: '0' }}>
        <ModalHeading>
          <h4 style={{ fontSize: '2.25rem' }}>{renderHeading()}</h4>
        </ModalHeading>
        <StyledHeader style={{ marginTop: '0px' }}>
          This feature is not included in the PWS Portal account procured on
          your behalf.
          <br />
          <br />
          If you would like to learn more about this feature, please reach out
          to{' '}
          <EmailLink href="mailto:support@120water.com">
            support@120water.com
          </EmailLink>
          . Communication templates can be downloaded in the{' '}
          <Link onClick={onClose} to={`/administration/resources`}>
            Resources
          </Link>{' '}
          tab on the Account Settings page.
        </StyledHeader>
      </ModalWrapper>
    )
  }

  if (!accountHasCommsAutomatedMailingFeature) {
    return (
      <ModalWrapper>
        <ModalHeading>
          <h5>{renderHeading()}</h5>
        </ModalHeading>
        <StyledHeader>
          Want to use this feature?{' '}
          <SupportLink>Reach out to support</SupportLink> to upgrade your
          package!
        </StyledHeader>
      </ModalWrapper>
    )
  }

  if (isLoadingComms) {
    return <Loader />
  }

  if (communications && communications.length < 1) {
    return <NoTemplatesWarning onClose={onCloseWindow} />
  }

  return (
    <ModalWrapper>
      <Wrapper
        style={{
          overlay: {
            zIndex: `${zIndex}`
          }
        }}>
        <ModalHeading>
          <Icon as={renderIcon()} />
          <h5 style={{ textAlign: 'center' }}>{renderHeading()}</h5>
        </ModalHeading>
        <React.Suspense fallback={<Loader />}>
          {(!isCommunicationPostLoading && (
            <>
              {!errorMessage && (
                <ModalContent>
                  <SendModalContent
                    communications={communications}
                    fetchCommunicationData={fetchCommunicationData}
                    hasAutomatedLetterMailingFeature={
                      !!accountHasCommsAutomatedMailingFeature
                    }
                    isCertifiedMail={isCertifiedMail}
                    isPlural={isPlural}
                    locationIds={baseLocationIds}
                    onCloseWindow={onCloseWindow}
                    onCommunicationSelected={onCommunicationSelected}
                    onCommunicationTypeSelected={onCommunicationTypeSelected}
                    selectedCommunication={selectedCommunication}
                    selectedCommunicationType={selectedCommunicationType}
                    stepNumber={stepNumber}
                    toggleIsCertifiedMail={toggleIsCertifiedMail}
                  />
                </ModalContent>
              )}
              {errorMessage && (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <ErrorMessage style={{ fontSize: '14px' }}>
                    {errorMessage}
                  </ErrorMessage>
                  <div
                    style={{
                      textAlign: 'center',
                      marginBottom: '15px',
                      fontSize: '14px'
                    }}>
                    If your letters are being sent by 120Water, please check the{' '}
                    <Link
                      onClick={onCloseWindow}
                      to={'/communications/letter-logs'}>
                      Letter Log
                    </Link>{' '}
                    prior to trying again to avoid duplicate sends.
                  </div>
                </div>
              )}
              <ButtonsWrapper>
                <SendModalFooter
                  disabledForError={!!errorMessage}
                  isSaving={isCommunicationPostLoading}
                  onBack={onBackClicked}
                  onClose={onClose}
                  onDownload={onDownload}
                  onNext={onNextClicked}
                  onSend={onSend}
                  selectedCommunication={selectedCommunication}
                  selectedCommunicationType={selectedCommunicationType}
                  stepNumber={stepNumber}
                />
              </ButtonsWrapper>
              {openPreviewContainer &&
                !isLoadingComms &&
                !isLoadingCommunication &&
                communicationData && (
                  <PDFModalContainerV2
                    accountId={accountId}
                    closePreview={closePreview}
                    data={communicationData}
                    isOpen={openPreviewContainer}
                    setErrorMessage={setErrorMessage}
                    setZIndex={setZIndex}
                    toggle={togglePreviewContainer}
                  />
                )}
            </>
          )) || <Loader />}
        </React.Suspense>
      </Wrapper>
    </ModalWrapper>
  )
}

interface Props {
  accountId: number
  locationIds: number[]
  locations: Location[]
  hideInventoryChoices?: boolean
  onClose: () => void
  onCompletion: () => void
  programId?: number
  eventId?: number
}

enum PDFSelection {
  'Letter as PDF' = 'Letter as PDF'
}

const CommunicationTypes = Object.assign({}, CommunicationType, PDFSelection)

// eslint-disable-next-line @typescript-eslint/no-redeclare
type CommunicationTypes = keyof typeof CommunicationTypes

const Icon = styled.img`
  width: 55px;
  height: 55px;
`
const StyledHeader = styled.h4`
  font-weight: 500;
`

const EmailLink = styled.a`
  text-decoration: none;
`

export default React.memo(SendCommunicationModalTwo)
